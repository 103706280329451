<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Settings</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Site Maintenance Mode
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <v-row>
              <v-col cols="12" md="12">
                <div class="text-center my-4">
                  <h3>Site Maintenance Mode is Currently  {{siteMaintenance.status_text}}</h3>
                  <a @click="toggleSiteMaintenance()" class="btn btn-warning ml-2 mt-4 btn-lg font-weight-bolder font-size-sm">
                    <i class="fa fa-spinner"></i> Click Here To Change
                  </a>
                </div>
              </v-col>
            </v-row>
          </div>

          <v-dialog
            v-model="dialog"
            height="500px"
            max-width="600px">
            <v-card>
              <v-toolbar
                color="primary"
                dark
              >
                <h4>Maintenance Mode</h4>
              </v-toolbar>
              <form @submit.prevent="updateSiteMaintenance()">
                <v-card-text>
                  <v-container>
                    <p class="text-h6">
                      <b>Note: </b> Enabling this mode will disable maintenance mode message to General User, Enroller, Exam Candidate & Course Candidate. Public access to the site will be blocked till this mode is activated again. For security your IP and user is tracked.
                    </p>
                    <v-row>
                      <v-col cols="12">
                        <label for="" class="text-h6">Maintenance Mode</label>
                        <v-radio-group
                            v-model="siteMaintenance.status"
                            row
                        >
                          <v-radio
                              label="Active"
                              value="active"
                          ></v-radio>
                          <v-radio
                              label="Inactive"
                              value="in_active"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <label class="text-title mt-2">Password *</label>
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          label="Enter Your Password to Continue"
                          type="password"
                          v-model="siteMaintenance.password"
                          outlined
                          dense
                        >
                        </v-text-field>
                        <span
                          class="text-danger"
                          v-if="$v.siteMaintenance.password.$error"
                        >
                          This information is required
                        </span>
                        <span class="text-danger"  v-if="!$v.siteMaintenance.password.minLength">
                          *Password must have at least 6 characters.
                        </span>
                        <span class="text-danger" v-if="errors.password" >*{{errors.password[0]}}</span>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="btn btn-primary"
                      dark
                      medium
                      type="submit"
                  >
                    Save Changes
                  </v-btn>
                  <v-btn
                      color="btn btn-standard"
                      dark
                      medium
                      @click="closeDialog"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </form>
            </v-card>
          </v-dialog>

        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import SiteMaintenanceService from "@/services/cms/setting/SiteMaintenanceService";
const siteMaintenanceService = new SiteMaintenanceService();
import {required, minLength} from "vuelidate/lib/validators";

export default {
  validations:{
    siteMaintenance:{
      password: {required, minLength: minLength(6)},
    }
  },
  data(){
    return{
      siteMaintenance: {
        id: null,
        status : '',
        title : '',
        status_text : '',
      },
      dialog: false,
      errors: [],
    }
  },
  methods: {
    openDialog(){
      this.dialog = true;
    },
    closeDialog(){
      this.dialog = false;
      this.siteMaintenance = {};
    },
    toggleSiteMaintenance() {
      this.openDialog();
    },
    getAllSiteMaintenance(){
      siteMaintenanceService
      .first()
      .then((response) => {
        this.siteMaintenance = response.data.data;
      })
    },
    updateSiteMaintenance(){
      this.$v.$touch()
      if(this.$v.$error){
        setTimeout(() => {
          this.$v.$reset()
        }, 3000)
      }
      else {
        siteMaintenanceService
        .update(this.siteMaintenance.id, this.siteMaintenance)
        .then(response => {
          this.$snotify.success("updated");
          this.closeDialog();
          this.getAllSiteMaintenance();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
      }
    }
  },
  mounted(){
    this.getAllSiteMaintenance();
  }
}
</script>
